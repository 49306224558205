<template>
  <ElSpinner
    color="primary"
    :radius="50"
  />
</template>

<script>
export default {
  name: 'Impersonation',
  setup(props, context) {
    context.root.$store.dispatch('Auth/me').then(() => {
      context.root.$router.replace({
        name: 'app.properties',
      });
    });
  },
};
</script>
